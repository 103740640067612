import axios from 'axios';
import { Job } from '../types';

const API_URL = 'http://37.27.27.206:8080/api/v1/';
//const API_URL = 'http://localhost:8080/api/v1/';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const authApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const responseInterceptor = (error: any) => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem('token');
    window.location.href = '/login';
  }
  return Promise.reject(error);
};

api.interceptors.response.use((response) => response, responseInterceptor);
authApi.interceptors.response.use((response) => response, responseInterceptor);

// Auth işlemleri için fonksiyonlar
export const login = async(email: string, password: string) => {
  return authApi.post('/auth/login', { email, password });
};

export const register = (email: string, password: string) => {
  return authApi.post('auth/register', { email, password });
};

// Diğer API çağrıları
export const getUserProfiles = (isApproved : string = 'true') => {
  return authApi.get(`/employee?isApproved=${isApproved}`);
};

export const getUserProfile = (id: number) => {
  return authApi.get(`/employee/${id}`);
};

export const updateUserProfile = (data: any) => {
  const id = localStorage.getItem('userId');
  return api.patch(`/employee/${id}`, data);
};

export const createProfile = (profile: any) => {
  return api.post('/employee', profile);
};

export const deleteProfile = (id: number) => {
  return api.delete(`/employee/${id}`);
};

export const approveProfile = (id: number) => {
  return api.patch(`/employee/${id}/approve`);
};

export const getJobs = (isApproved = 'true') => {
  return api.get(`/job?isApproved=${isApproved}`);
}

export const createJobOffer = (job: Job) => {
  return api.post('/job', job);
}

export const deleteJob = (id: number) => {
  return api.delete(`/job/${id}`);
}

export const updateJob = (id: number, data: any) => {
  return api.patch(`/job/${id}`, data);
}

export default api;