import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import ProfileCard from './ProfileCard';
import ProfileDetail from './ProfileDetail';
import { Profile } from './types';
import { LanguageCode, translations } from './translations';
import {  getUserProfiles } from './api/api';
import { useAuth } from './contexts/AuthContext';

const JobProfileDashboard: React.FC = () => {
    const [selectedProfile, setSelectedProfile] = useState<Profile | null>(null);
    const [language, setLanguage] = useState<LanguageCode>('en');
    const [profiles, setProfiles] = useState<Profile[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProfiles, setFilteredProfiles] = useState<Profile[]>([]);
    const t = translations[language];
    const { isLoggedIn } = useAuth();

    useEffect(() => {
      const fetchProfiles = async () => {
          try {
              const response = await getUserProfiles();
              setProfiles(response.data);
          } catch (err) {
              console.error('Error fetching profiles:', err);
          }
      };

      fetchProfiles();
  }, []);

  useEffect(() => {
    const filtered = profiles.filter(profile => {
      const techMatch = profile.technologies && profile.technologies.some(tech => 
          tech.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      const titleMatch = profile.title && profile.title.toLowerCase().includes(searchTerm.toLowerCase());
      return techMatch || titleMatch;
  });
    setFilteredProfiles(filtered);
  }, [searchTerm, profiles]);

    const toggleLanguage = () => {
        setLanguage(language === 'en' ? 'tr' : 'en');
      };

  const handleProfileClick = (profile: Profile) => {
    setSelectedProfile(profile);
  };

  const handleLanguageChange = (newLanguage: LanguageCode) => {
    setLanguage(newLanguage);
  };

  const handleSearch = (event: any) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
  };

  return (
    <div className="container-fluid p-0">
      <div className="p-3">
        <Row>
          <Col md={4}>
            <Form.Control 
              type="text" 
              placeholder={t.search} 
              className="mb-3" 
              onChange={handleSearch} 
              value={searchTerm} 
            />
            {filteredProfiles.length === 0 && <p>{t.noTitle}</p>}
            {filteredProfiles.map((profile) => (
              <ProfileCard 
                key={profile.id} 
                profile={profile} 
                onClick={handleProfileClick} 
                language={language} 
              />
            ))}
          </Col>
          <Col md={8}>
            {selectedProfile && isLoggedIn &&
            <ProfileDetail 
              profile={selectedProfile} 
              language={language}
              isAdmin = {localStorage.getItem('userLevel') === 'ADMIN'}
              />}
          </Col>
        </Row>
      </div>
    </div>
  );
};


export default JobProfileDashboard;