import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, Button, Row, Col, Card, Container } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { UserProfile } from './types';
import { getUserProfile, updateUserProfile } from './api/api';
import './css/ProfileForm.css';
import { formatToBackendDate } from './util/DateUtil';
import { toast } from 'react-toastify';
import { useLanguage } from './contexts/LanguageContext';
import { useAuth } from './contexts/AuthContext';

const RequiredLabel: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Form.Label>
    {children}
    <span className="text-danger ml-1">*</span>
  </Form.Label>
);

const schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    surname: yup.string().required('Surname is required'),
    phoneNumber: yup.string().required('Phone number is required'),
    title: yup.string().required('Title is required'),
    experience: yup.number().positive().integer().required('Experience is required'),
    location: yup.string().required('Location is required'),
    birthDate: yup.string().required('Birth date is required'),
    availability: yup.string().required('Availability is required'),
    expectedNetSalary: yup.number().positive().required('Expected net salary is required'),
    noticePeriodDay: yup.number().positive().integer().required('Notice period is required'),
    isProfileCreated: yup.boolean().optional(),
    linkedin: yup.string().url('Invalid URL').required('Linkedin is required'),
    aboutMe: yup.string().required("About me is required"),
    technologies: yup.array().of(
      yup.object().shape({
        name: yup.string().required('Skill name is required'),
      })
    ).required(),
    language: yup.array().of(
      yup.object().shape({
        name: yup.string().required('Language name is required'),
        level: yup.string().required('Language level is required')
      })
    ),
    education: yup.array().of(
      yup.object().shape({
        name: yup.string().required('School name is required'),
        department: yup.string(),
        startDate: yup.string().required('Start date is required'),
        endDate: yup.string().nullable()
      })
    ).required(),
    experiences: yup.array().of(
      yup.object().shape({
        company: yup.string().required('Company name is required'),
        title: yup.string().required('Position is required'),
        startDate: yup.string().required('Start date is required'),
        endDate: yup.string().nullable(),
        description: yup.string().optional(),
        isCurrentJob: yup.boolean().optional()
      })
    )
  });

const AVAILABILITY_OPTIONS = [
  'READY_TO_INTERVIEW',
  'AVAILABLE_SOON',
  'CURRENTLY_AVAILABLE',
  'CURRENTLY_UNAVAILABLE',
  'FREELANCE_ONLY',
  'CONTRACT_ONLY'
];

const ISACTIVE_OPTIONS = [
  true, false
]

const UpdateProfile: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { t } = useLanguage();
  const { userId } = useAuth();

  const { register, control, handleSubmit, reset, formState: { errors } } = useForm<UserProfile>({
    resolver: yupResolver(schema as any),
    defaultValues: {
        technologies: [],
        language: [],
        education: [],
        experiences: []
      },
  });

  const { fields: skillFields, append: appendSkill, remove: removeSkill } = useFieldArray({
    control,
    name: "technologies"
  });

  const { fields: languageFields, append: appendLanguage, remove: removeLanguage } = useFieldArray({
    control,
    name: "language"
  });

  const { fields: educationFields, append: appendEducation, remove: removeEducation } = useFieldArray({
    control,
    name: "education"
  });

  const { fields: experienceFields, append: appendExperience, remove: removeExperience } = useFieldArray({
    control,
    name: "experiences"
  });

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        setIsLoading(true);
        const response = await getUserProfile(userId);
        reset(response.data);
        setError(null);
      } catch (err) {
        setError('Failed to fetch user profile. Please try again.');
        console.error('Error fetching user profile:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  const onSubmit = async (data: UserProfile) => {
    try {
      const response = await updateUserProfile(data);
      if (response.status === 200 || response.status === 201) {
        toast.success('Update is successfull');
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Failed to update profile:', error);
      toast.error('Error');
    }
  };

  const extractErrorMessages = (errors: any): string[] => {
    let messages: string[] = [];
    
    if (typeof errors === 'string') {
      messages.push(errors);
    } else if (errors && typeof errors === 'object') {
      Object.values(errors).forEach((error: any) => {
        if (error?.message && typeof error.message === 'string') {
          messages.push(error.message);
        } else if (Array.isArray(error)) {
          error.forEach(item => {
            messages = messages.concat(extractErrorMessages(item));
          });
        } else if (typeof error === 'object') {
          messages = messages.concat(extractErrorMessages(error));
        }
      });
    }
    
    return messages;
  };

  const onError = (errors: any, e: React.BaseSyntheticEvent | undefined) => {
    console.log('Form errors:', errors);
  
    const errorMessages = extractErrorMessages(errors);
    
    errorMessages.forEach(message => {
      toast.error(message);
    });
  };

  if (isLoading) {
    return <div>Loading profile...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Container className="mt-4" style={{ maxWidth: '800px' }}>
      <Card className="shadow-sm">
        <Card.Body className="p-4">
          <h2 className="mb-4 text-primary">{t('update_header')}</h2>
          <Form onSubmit={handleSubmit(onSubmit, onError)}>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                <RequiredLabel>{t('name')}</RequiredLabel>
                  <Form.Control {...register('name')} />
                  {errors.name && <Form.Text className="text-danger">{errors.name.message}</Form.Text>}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <RequiredLabel>{t('surname')}</RequiredLabel>
                  <Form.Control {...register('surname')} />
                  {errors.surname && <Form.Text className="text-danger">{errors.surname.message}</Form.Text>}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <RequiredLabel>{t('phoneNumber')}</RequiredLabel>
                  <Form.Control {...register('phoneNumber')} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <RequiredLabel>{t('title')}</RequiredLabel>
                  <Form.Control {...register('title')} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group className='mb-3'>
                  <RequiredLabel>{t('about')}</RequiredLabel>
                  <Form.Control {...register('aboutMe')}
                                    as="textarea" 
                                    rows={5}
                                    style={{ resize: 'vertical', minHeight: '150px' }}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <RequiredLabel>{t('experience')} ({t('year')})</RequiredLabel>
                  <Form.Control type="number" {...register('experience')} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('countryOfResidence')}</Form.Label>
                  <Form.Control {...register('location')} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <RequiredLabel>{t('birthDate')}</RequiredLabel>
                  <Controller
                    control={control}
                    name="birthDate"
                    render={({ field }) => (
                      <DatePicker
                        selected={field.value ? new Date(field.value) : null}
                        onChange={(date: Date | any) => field.onChange(formatToBackendDate(date))}
                        className="form-control"
                        dateFormat="yyyy-MM-dd"
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={100}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <RequiredLabel>{t('availability')}</RequiredLabel>
                  <Form.Select {...register('isActive')}>
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('expectedNetSalary')}</Form.Label>
                  <Form.Control type="number" {...register('expectedNetSalary')} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('noticePeriod')} ({t('days')})</Form.Label>
                  <Form.Control type="number" {...register('noticePeriodDay')} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
            <Col md={12}>
                <Form.Group className="mb-3">
                  <RequiredLabel>Linkedin</RequiredLabel>
                  <Form.Control type="text" {...register('linkedin')} />
                </Form.Group>
              </Col>
            </Row>

            <h3 className="mt-4 mb-3">{t('digitalSkill')}</h3>
            {skillFields.map((field, index) => (
              <Row key={field.id}>
                <Col md={5}>
                  <Form.Group className="mb-3">
                    <Form.Control {...register(`technologies.${index}.name`)} placeholder="Skill name" />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Button className="custom-btn" onClick={() => removeSkill(index)}>{t('remove')}</Button>
                </Col>
              </Row>
            ))}
            <Button className="custom-btn" onClick={() => appendSkill({ name: '' })}>
              {t('add_skill')}
            </Button>
            <h3 className="mt-4 mb-3">{t('languages')}</h3>
            {languageFields.map((field, index) => (
              <Row key={field.id}>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Control {...register(`language.${index}.name`)} placeholder="Language name" />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Control {...register(`language.${index}.level`)} placeholder="Language level" />
                  </Form.Group>
                  <Button className="custom-btn-remove" onClick={() => removeLanguage(index)}>{t('remove')}</Button>
                </Col>
              </Row>
            ))}
            <Button className="custom-btn" onClick={() => appendLanguage({ name: '', level: '' })}>
              {t('add_language')}
            </Button>
            <h3 className="mt-4 mb-3">{t('education')}</h3>
            {educationFields.map((field, index) => (
              <Card key={field.id} className="mb-3">
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('name')}</Form.Label>
                        <Form.Control {...register(`education.${index}.name`)} />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('start_date')}</Form.Label>
                        <Controller
                          control={control}
                          name={`education.${index}.startDate`}
                          render={({ field }) => (
                            <DatePicker
                              selected={field.value ? new Date(field.value) : null}
                              onChange={(date) => field.onChange(date?.toISOString().split('T')[0])}
                              className="form-control"
                              dateFormat="yyyy-MM-dd"
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={100}
                            />
                          )}
                        />
                      </Form.Group>
                    </Col>

                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('department')}</Form.Label>
                        <Form.Control {...register(`education.${index}.department`)} />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('end_date')}</Form.Label>
                        <Controller
                          control={control}
                          name={`education.${index}.endDate`}
                          render={({ field }) => (
                            <DatePicker
                              selected={field.value ? new Date(field.value) : null}
                              onChange={(date) => field.onChange(formatToBackendDate(date))}
                              className="form-control"
                              dateFormat="yyyy-MM-dd"
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={100}
                            />
                          )}
                        />
                      </Form.Group>
                    </Col>

                    <Button className="custom-btn-remove" onClick={() => removeEducation(index)}>{t('remove')}</Button>
                  </Row>
                </Card.Body>
              </Card>
            ))}
            <Button className="custom-btn" onClick={() => appendEducation({ name: '', department: '', startDate: '', endDate: '' })}>
              {t('add_education')}
            </Button>

          <h3 className="mt-4 mb-3">{t('work_experience')}</h3>
            {experienceFields.map((field, index) => (
              <Card key={field.id} className="mb-4 border-0 shadow-sm">
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('company')}</Form.Label>
                        <Form.Control {...register(`experiences.${index}.company`)} />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('title')}</Form.Label>
                        <Form.Control {...register(`experiences.${index}.title`)} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('start_date')}</Form.Label>
                        <Controller
                          control={control}
                          name={`experiences.${index}.startDate`}
                          render={({ field }) => (
                            <DatePicker
                              selected={field.value ? new Date(field.value) : null}
                              onChange={(date: Date | any) => field.onChange(formatToBackendDate(date))}
                              className="form-control"
                              dateFormat="yyyy-MM-dd"
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={100}
                            />
                          )}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('end_date')}</Form.Label>
                        <Controller
                          control={control}
                          name={`experiences.${index}.endDate`}
                          render={({ field }) => (
                            <DatePicker
                              selected={field.value ? new Date(field.value) : null}
                              onChange={(date: Date | any) => field.onChange(formatToBackendDate(date))}
                              className="form-control"
                              dateFormat="yyyy-MM-dd"
                            />
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3">
                    <Form.Label>{t('description')}</Form.Label>
                    <Form.Control {...register(`experiences.${index}.description`)} as="textarea" rows={3} />
                  </Form.Group>
                  <Button className="custom-btn-remove" onClick={() => removeExperience(index)}>{t('remove')}</Button>
                </Card.Body>
              </Card>
            ))}

            <div className="d-flex justify-content-between mt-4">
              <Button 
                className="custom-btn"
                type='button'
                onClick={() => appendExperience({ company: '', title: '', startDate: '', endDate: '', description: '' })}
              >
                {t('add_experience')}
              </Button>
              <Button 
                className="custom-btn-create"
                type="submit" 
              >
                {t('update_profile')}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default UpdateProfile;